const HomeTutorial = () => {
    return ( 
        <section id="tutorial" className="mt-20 md:mt-32">
            <div className="px-4 container mx-auto font-primary text-center">
                <div className="w-full flex flex-col justify-center items-center">
                    <h3 className="font-light text-2xl">آموزش تصویری استفاده در دستگاهای مختلف</h3>
                    <p className="mt-8 font-thin">
                     بعد از خرید اکانت وی پی ان سایت ما برای سهولت نصب و اتصال کاربران اقدام به ساخت برنامه های کاربردی خود کرده است که با استفاده از این برنامه ها شما میتوانید به آسانی و بدون تنظیمات دستی به VPN پرسرعت ما متصل شوید. 
                    </p>
                    <div className="w-full mt-8 grid grid-cols-1 md:grid-cols-4 gap-8">
                        <div className="flex flex-col items-center">
                            <button className="px-6 py-3 bg-gradient-to-t from-dark to-primary hover:bg-gradient-to-l hover:from-primary hover:to-secondary hover:scale-105 rounded-lg font-normal transition-all">
                                آموزش اتصال در اندروید
                            </button>
                            <video controls className="mt-4 w-full max-w-sm">
                                <source src="https://panel.luffyvpn.online/uploads/android.MP4" type="video/mp4" />
                                مرورگر شما از پخش ویدئو پشتیبانی نمی‌کند.
                            </video>
                        </div>
                        <div className="flex flex-col items-center">
                            <button className="px-6 py-3 bg-gradient-to-t from-primary to-secondary hover:bg-gradient-to-l hover:from-primary hover:to-secondary hover:scale-105 rounded-lg font-normal transition-all">
                                آموزش اتصال در آیفون
                            </button>
                            <video controls className="mt-4 w-full max-w-sm">
                                <source src="https://panel.luffyvpn.online/uploads/iphone.MP4" type="video/mp4" />
                                مرورگر شما از پخش ویدئو پشتیبانی نمی‌کند.
                            </video>
                        </div>
                        <div className="flex flex-col items-center">
                            <button className="px-6 py-3 bg-gradient-to-t from-primary to-secondary hover:bg-gradient-to-l hover:from-primary hover:to-secondary hover:scale-105 rounded-lg font-normal transition-all">
                                آموزش اتصال در مک
                            </button>
                            <video controls className="mt-4 w-full max-w-sm">
                                <source src="https://panel.luffyvpn.online/uploads/iphone.MP4" type="video/mp4" />
                                مرورگر شما از پخش ویدئو پشتیبانی نمی‌کند.
                            </video>
                        </div>
                        <div className="flex flex-col items-center">
                            <button className="px-6 py-3 bg-gradient-to-t from-primary to-secondary hover:bg-gradient-to-l hover:from-primary hover:to-secondary hover:scale-105 rounded-lg font-normal transition-all">
                                آموزش اتصال در ویندوز
                            </button>
                            <video controls className="mt-4 w-full max-w-sm">
                                <source src="https://panel.luffyvpn.online/uploads/windows.MOV" type="video/mp4" />
                                مرورگر شما از پخش ویدئو پشتیبانی نمی‌کند.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default HomeTutorial;
