import { FaBeer } from 'react-icons/fa';


const data = [
    {
        id:1,
        title: 'درآمد دلاری',
        content: 'شما می توانید با دعوت از مصرف کنندگان وی پی ان به صورت مادام‌العمر از خرید آنها کسب درآمد داشته باشید.',
        icon:'public',
        image: './images/intro/global.svg'
    },
    {
        id:2,
        title: 'استفاده آسان از وی پی ان',
        content: 'با سرویس های ما میتوانید در هر دستگاهی استفاده کنید کامپیوتر ، تبلت ، آیفون ، اندروید ، مک ، روتر و غیره ، مهم نیست که در کجا هستید و یا از چه دستگاه هایی استفاده می کنید.',
        icon:'support_agent',
        image: './images/intro/message-time.svg'
    },
    {
        id:3,
        title: 'ویژگی Kill Switch',
        content: 'VPN kill switch یا همان Network Lock است. این قابلیت داده ها را حتی اگر اتصال VPN شما قطع شود ، ایمن نگه می دارد و تا زمان بازیابی محافظت ، همه ترافیک اینترنت را مسدود می کند.',
        icon:'network_check',
        image: './images/intro/favorite-chart.svg'
    },
    {
        id:4,
        title: 'پروتکل جدید و متنوع بالا',
        content: 'ما پروتکل جدید را به برنامه های کاربردی اضافه کرده ایم این پروتکل مناسب دانلود و تماشای فیلم و وب گردی و استفاده از تلگرام میباشد و دارای لوکیشن های مختلفی از نقاط مختلف جهان میباشد.',
        icon:'gpp_good',
        image: './images/intro/shield-security.svg'
    }
]

const HomeIntroItem = (props) => {
    return (
        <div className="introItem relative mt-4 hover:mt-0 myAnimation1">
            <span className="introItemLamp absolute -top-1 h-4 rounded-md z-0 myAnimation1"></span>
            <div className="relative w-full px-4 py-6 z-10 flex flex-col items-center bg-white rounded-2xl backdrop-blur-2xl bg-opacity-5">
                <span class="material-icons text-2xl myAnimation1">
                    {props.item.icon}
                </span>
                <h4 className="mt-4 mb-4 text-lg myAnimation1">{props.item.title}</h4>
                <p className="font-thin leading-loose opacity-70">{props.item.content}</p>
            </div>
        </div>
    )
}

const HomeIntro = () => {
    return ( 
        <section id="whyluffy" className="mt-16 select-none">
            <div className="container mx-auto px-4 font-primary text-center">
                <h3 className="text-center font-regular text-2xl">
                    چرا لوفی را انتخاب کنیم؟
                </h3>
                <div className="mt-16 px-4 md:px-0 grid grid-cols-1 md:grid-cols-4 gap-8">
                    {data.map(item => 
                        <HomeIntroItem key={item.id} item={item} />    
                    )}
                </div>
            </div>
        </section>
     );
}
 
export default HomeIntro;